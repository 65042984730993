import { OrderQuote } from "views/order/index";
import { Dashboard } from "views/projects/index";
import { Uploads } from "views/projects/index";
import { Downloads } from "views/projects/index";
import { Projects } from "views/projects/index";
import { Reports } from "views/reports/index";
import Board from "components/projects/Board";

// PROJETOS
import {
  TourVirtual,
  TourVirtual2,
  GemeoDigital,
  Cartografia
} from "views/projects/index";

// USER
import { UserProfile } from "views/user/index";

// ADMIN
import { Workflow } from "views/admin/index";

//TESTES
import bucketList from "views/tests/bucketList";
import formMail from "views/tests/formMail";
import uploadFiles from "views/tests/uploadFiles";

const dashboardRoutes = [
  { path: "/order-quote", component: OrderQuote },
  { path: "/dashboard", component: Dashboard },
  { path: "/uploads", component: Uploads },
  { path: "/downloads", component: Downloads },
  { path: "/projects", component: Projects },
  { path: "/projects/board/:id", component: Board },

  { path: "/tour", component: TourVirtual },
  { path: "/tour2/:id", component: TourVirtual2 },
  { path: "/gemeo-digital", component: GemeoDigital },
  { path: "/cartografia", component: Cartografia },

  { path: "/perfil", component: UserProfile },

  { path: "/workflow", component: Workflow },
  { path: "/bucketlist", component: bucketList },
  { path: "/form-mail", component: formMail },
  { path: "/upload-files", component: uploadFiles },
  { path: "/reports", component: Reports }

  // { path: "/order-quote", component: OrderQuote },
  // { path: "/tour2/:id", component: TourVirtual2 },
  // { path: "/gemeo-digital", component: GemeoDigital },
  // { path: "/cartografia", component: Cartografia },
  // { path: "/perfil", component: UserProfile },
  // { path: "/workflow", component: Workflow },
  // { path: "/bucketlist", component: bucketList },
  // { path: "/form-mail", component: formMail },
  // { path: "/upload-files", component: uploadFiles }
];

export default dashboardRoutes;
